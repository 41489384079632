import './Home.css'
import {Outlet, Link} from "react-router-dom";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import {database} from '../firebase'
import {ref, push, child, update} from "firebase/database";
import {useState, setState, useRef, useCallback} from 'react';
import Reaptcha from 'reaptcha';
import MenuItem from '@mui/material/MenuItem';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';
import EmbedSocialWidget from './EmbedSocialWidget';

const Home = () => {
    const settings = {
        dots: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,

        responsive: [
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
      };

    const [divItem1Open, setDivItem1Open] = React.useState(false);
    const [divItem2Open, setDivItem2Open] = React.useState(false);
    const [divItem3Open, setDivItem3Open] = React.useState(false);
    const [divItem4Open, setDivItem4Open] = React.useState(false);
    const [divItem5Open, setDivItem5Open] = React.useState(false);

    // const divItem1 = document.getElementById('medical-bracelet');
    // divItem1.addEventListener('click', (e) => {
    //     setDivItem1Open(true);
    //     console.log(divItem1Open)
    // });

    const medicalBraceletClick = () => {
        setDivItem1Open(true);
    };
    const medicalBraceletClose = () => setDivItem1Open(false);

    const journalClick = () => {
        setDivItem2Open(true);
    };
    const journalClose = () => setDivItem2Open(false);

    const groupsClick = () => {
        setDivItem3Open(true);
    };
    const groupsClose = () => setDivItem3Open(false);

    const educationClick = () => {
        setDivItem4Open(true);
    };
    const educationClose = () => setDivItem4Open(false);

    const lawClick = () => {
        setDivItem5Open(true);
    };
    const lawClose = () => setDivItem5Open(false);

    


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'purple',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };

    const selections = [

        {
            value: 'Psychologist',
            label: 'Psychologist',
        },
        {
            value: 'Psychiatrist',
            label: 'Psychiatrist',
        },
        {
            value: 'Psychotherapist',
            label: 'Psychotherapist',
        },
        {
            value: 'Social Worker',
            label: 'Social Worker',
        },
        {
            value: 'Psychological Associate',
            label: 'Psychological Associate',
        },
        {
            value: 'Family & Marriage Therapist',
            label: 'Family & Marriage Therapist',
        },
        {
            value: 'Marriage Therapist',
            label: 'Marriage Therapist',
        },
        {
            value: 'Counselor',
            label: 'Counselor',
        },
        {
            value: 'Family Doctors',
            label: 'Family Doctors',
        },
        {
            value: 'Other',
            label: 'Other',
        },
    ];

    const [captchaToken, setCaptchaToken] = useState(null);

    const [disableSubmit, setDisableSubmit] = useState(true);
    const captchaRef = useRef(null);

    const verify = () => {
        captchaRef.current.getResponse().then(res => {
            setCaptchaToken(res)
            setDisableSubmit(false)
        })

    }

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const [name, setName] = useState(null);
    const [email, setEmail] = useState(null);
    const [number, setNumber] = useState(null);
    const [select, setSelect] = useState(null);


    const handleInputChange = (e) => {
        const {id, value} = e.target;
        if (id === "name") {
            setName(value);
        }
        if (id === "email") {
            setEmail(value);
        }
        if (id === "number") {
            setNumber(value);
        }
    }
    const handleChange = (event) => {
        setSelect(event.target.value);
    };

    const handleSubmit = () => {

        setOpen(false);

        let obj = {
            name: name,
            email: email,
            number: number,
            select: select,
        }
        const newPostKey = push(child(ref(database), 'posts')).key;
        const updates = {};
        updates['/' + newPostKey] = obj
        setDisableSubmit(true)
        return update(ref(database), updates);

    }

    return (

        <div className="home-flex-box">

            
            <div className="flex-box-2">


                <div className='text'>
                    <h1>Bigger picture, better outcomes.</h1>
                </div>

           
            <Slider className= "Slider"{...settings}>

                <div className='outer'>
                <div id='medical-bracelet' className="home-card" onClick={medicalBraceletClick}> 
                <p className = "field">Mental Health</p>
                <h1>Making our world safer</h1>
                <p>Emergency Medical Bracelet</p>
                <div className = "img-box"> 
                <img  className='bracelet' src="./images/medical alert bracelet (1).png" alt="Medical Alert Bracelet"/>
                </div>
                </div >
                </div>

                <div className='outer'>
                <div  className="home-card" onClick={educationClick}> 
                <p className = "field">Education</p>
                <h1>Be the first to hear about it</h1>
                <p>Coming soon</p>
                <div className = "img-box"> 
                <img className='laptop' src="./images/education demo 3.png" alt="Education"/>
                </div>
                </div>
                </div>

                <div className='outer'>
                <div className="home-card" onClick={journalClick}> 
                <p className = "field">Mental Health</p>
                <h1>Making our world aware</h1>
                <p>Journal</p>
                <div className = "img-box">    
                <img className='phone' src="./images/journal.png" alt="Journal Screenshot"/>
                </div>
                </div>
                </div>

                <div className='outer'>
                <div className="home-card" onClick={lawClick}> 
                <p className = "field">Law</p>
                <h1>Be the first to hear about it</h1>
                <p>Coming soon</p>
                <div className = "img-box"> 
                <img className='laptop' src="./images/law demo (1).png" alt="Law"/>
                </div>
                </div>
                </div>

                <div className='outer'>
                <div className="home-card" onClick={groupsClick} > 
                <p className = "field">Mental Health</p>
                <h1>Making our world connect</h1>
                <p>Groups</p>
                <div className = "img-box"> 
                <img  className='phone' src="./images/group-session-screenshot.png" alt="Group Session Screenshot"/>
                </div>
                </div>
                </div>
              </Slider>
                
            
                
                <div>
                <Modal
                open={divItem1Open}
                  onClose={medicalBraceletClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <div className='carousel-popup'>
                    <div className='row'>
                    <div className='column-double'>
                    <div className='header'>
                    <h3>
                      Mental Health
                    </h3>
                    <h1>
                      Enhancing Our Safety
                    </h1>
                    <h3>
                        Medical Alert Bracelet
                    </h3>
                    </div>
                    </div>
                    <div className='column'>
                    <Button onClick={medicalBraceletClose} variant="contained" className='close'>
                        x
                    </Button>
                    </div>
                    </div>
                    <div className='row'>
                        <div className='column-double'>
                            <div className='body'>
                    <p> Saule is a mental health practitioner app that features an innovative medical alert 
                        bracelet. The bracelet includes an embedded QR code that, when scanned, quickly provides 
                        access to the patient's medical information. Only the information that the patient wishes 
                        to share with other mental health experts and EMS is shared, ensuring confidentiality. 
                        This feature ensures the provision of timely and appropriate care during emergencies, 
                        with Saule providing a secure platform for the storage and sharing of sensitive medical 
                        information. </p>
                        </div>
                        <a href="https://www.joinsaule.com/features">
                        <Button variant="contained" className='learn-more'>
                        Learn More
                    </Button>
                    </a>
                        </div>
                    <div className='column'>
                        <div className='image-div'>
                    <img id='med-img' src='./images/medical alert bracelet (1).png' alt='Medical Alert Bracelet'/>
                    </div>
                    </div>
                    {/* <img className='mobile-med' src='./images/medical alert bracelet (1).png'/> */}
                    </div>
                  </div>
                </Modal>
              </div>

              <div>
                <Modal
                open={divItem2Open}
                  onClose={journalClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <div className='carousel-popup'>
                    <div className='row'>
                    <div className='column-double'>
                    <div className='header'>
                    <h3>
                      Mental Health
                    </h3>
                    <h1>
                      Enhancing Our Awareness
                    </h1>
                    <h3>
                      Journal
                    </h3>
                    </div>
                    </div>
                    <div className='column'>
                    <Button onClick={journalClose} variant="contained" className='close'>
                        x
                    </Button>
                    </div>
                    </div>
                    <div className='row'>
                        <div className='column-double'>
                            <div className='body'>
                    <p> Saule’s Journal feature allows practitioners to streamline their organization of clients and 
                        private/public notes for clients to document their progress. With customizable text, practitioners 
                        and clients have complete freedom to choose how they wish to document their progress. This innovative 
                        tool streamlines mental health care by providing a secure platform for effective treatment planning 
                        and communication between practitioners and clients. </p>
                        </div>
                        <a href="https://www.joinsaule.com/features">
                        <Button variant="contained" className='learn-more' >
                        Learn More
                    </Button>
                    </a>
                        </div>
                    <div className='column'>
                        <div className='image-div'>
                    <img src='./images/mental health journals demo1 3.png' alt='Journal Screenshot'/>
                    </div>
                    </div>
                    {/* <img className='mobile-journals' src='./images/mental health journals demo1 3.png'/> */}
                    </div>
                  </div>
                </Modal>
              </div>

              <div>
                <Modal
                open={divItem3Open}
                  onClose={groupsClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <div className='carousel-popup'>
                    <div className='row'>
                    <div className='column-double'>
                    <div className='header'>
                    <h3>
                      Mental Health
                    </h3>
                    <h1>
                      Connecting our world
                    </h1>
                    <h3>
                      Groups
                    </h3>
                    </div>
                    </div>
                    <div className='column'>
                    <Button onClick={groupsClose} variant="contained" className='close'>
                        x
                    </Button>
                    </div>
                    </div>
                    <div className='row'>
                        <div className='column-double'>
                            <div className='body'>
                    <p> Saule’s group feature makes group practice seamless with built-in video call, messaging 
                        system, and collaborative healthcare tools. This feature enables practitioners to connect
                        with multiple patients simultaneously, allowing for efficient and effective group therapy sessions. 
                        The app provides a secure platform for communication, collaboration, and documentation, enhancing 
                        the quality of mental health care services provided. </p>
                        </div>
                        <a href="https://www.joinsaule.com/features">
                        <Button variant="contained" className='learn-more'>
                        Learn More
                    </Button>
                    </a>
                        </div>
                    <div className='column'>
                        <div className='image-div'>
                    <img src='./images/phone_groupsDemo1 1.png' alt='Group Session Screenshot'/>
                    </div>
                    </div>
                    {/* <img className='mobile-groups' src='./images/phone_groupsDemo1 1.png'/> */}
                    </div>
                  </div>
                </Modal>

                <Modal
                open={divItem4Open}
                  onClose={educationClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <div className='carousel-popup'>
                    <div className='row'>
                    <div className='column-double'>
                    <div className='header'>
                    <h3 id='popup-bold'>
                      Education
                    </h3>
                    <h3>
                      Be The First to Hear About It
                    </h3>
                    <h1 id='coming-soon'>
                      Coming soon...
                    </h1>
                    </div>
                    </div>
                    <div className='column'>
                    <Button onClick={educationClose} variant="contained" className='close'>
                        x
                    </Button>
                    </div>
                    </div>
                    <div className='row'>
                        <div className='column-double'>
                            
                
                        </div>
                    <div className='column'>
                        <div className='image-div'>
                    <img id='med-img' src='./images/education demo 3.png' alt='Education'/>
                    </div>
                    </div>
                    </div>
                  </div>
                </Modal>

                <Modal
                open={divItem5Open}
                  onClose={lawClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <div className='carousel-popup'>
                    <div className='row'>
                    <div className='column-double'>
                    <div className='header'>
                    <h3 id='popup-bold'>
                      Law
                    </h3>
                    <h3>
                      Be The First to Hear About It
                    </h3>
                    <h1 id='coming-soon'>
                      Coming soon...
                    </h1>
                    </div>
                    </div>
                    <div className='column'>
                    <Button onClick={lawClose} variant="contained" className='close'>
                        x
                    </Button>
                    </div>
                    </div>
                    <div className='row'>
                        <div className='column-double'>
                            
                
                        </div>
                    <div className='column'>
                        <div className='image-div'>
                    <img id='med-img' src='./images/law demo (1).png' alt='Law'/>
                    </div>
                    </div>
                    </div>
                  </div>
                </Modal>

              </div>
              </div>
    
              <div className='home-wrapper'>
                <div className='home-section-two'>
                <div className='row'>
                    <div className='column'>
                       <h1> Determined to change the world <span className = "word-break"> one industry at a time. </span></h1>
                    </div>
                </div>
                <div className='row'>

                {/* <TwitterTimelineEmbed
  sourceType="profile"
  screenName="aversa_inc"
  options={{height: 400}}
/> */}
              
                    <div className='column'>

                        <Link to={"/about"}>
                <Button variant={"contained"} >About Us</Button>
                </Link>
                </div>
                </div>
                </div>

                <div className='socials'>
                    <div className='row'>
                        <div className='column'>
                        <h1>STAY IN TOUCH WITH AVERSA</h1>
                        <img className='border-social' src='./images/social_border.png' alt=''/>
                    </div>
                    </div>
                    <div className='row'>
                        <div className='column'>
                        <EmbedSocialWidget />
                        </div>
                    </div>
                </div>

                <div className='home-section-saule'>
                <div className='row'>
                    <div className='column'>
                        <h1 className='home-saule-header'>SAULE</h1>
                        <img className='border-saule' src='./images/saule_border.png' alt=''/>
                <div className='special'><h3>It takes 7 apps to support one client.</h3></div>
                </div>
                </div>
                <div className='row'>
                    <div className='column'>
                    <div className='features'>
                    <p className='special-p'>Why not just one?</p>
                    <div className='features-list'>
                    <img className='icon' src='./images/checkmark-icon.png' alt='Checkmark'/>
                    <h3 className='feature'>Session booking & scheduling</h3>
                    </div>
                    <div className='features-list'>
                    <img className='icon' src='./images/checkmark-icon.png' alt='Checkmark'/>
                    <h3 className='feature'>Seamless Referrals</h3>
                    </div>
                    <div className='features-list'>
                    <img className='icon' src='./images/checkmark-icon.png' alt='Checkmark'/>
                    <h3 className='feature'>Real-time Video Calling</h3>
                    </div>
                    <div className='features-list'>
                    <img className='icon' src='./images/checkmark-icon.png' alt='Checkmark'/>
                    <h3 className='feature'>Journaling & Note-taking features</h3>
                    </div>
                    </div>
                    <p className='other-features'>Among many other features.</p>
                    <a href='https://www.joinsaule.com'>
                    <Button variant={"contained"} className='join-saule-button'>Join Saule</Button>
                    </a>
                    </div>
                    <div className='column'>
                    <img className='aversa-screenshot' src='./images/iphone_home_screenshot-2.webp' alt='Saule Home Screenshot'/>
                    </div>
                    </div>
                    </div>
                    <div className='home-section-invest'>
                    <div className='row'>
                        <h2 className='invest-h2'>Invest in something bigger</h2>
                        <img className='mobile-border-invest' src='./images/invest-border.png' alt=''/>

                    </div>
                    <div className='row'>
                        <p className='aversa-desc'>Aversa is constantly growing and taking ecosystems to the next level.
                            Support our journey and reap the rewards of our revelations.
                        </p>
                    </div>
                    <div className='row'>
                    <div className='column'>
                    <a href='https://equivesto.com/aversa'>
                    <Button variant={"contained"} className='learn-more-button'>Learn More</Button>
                    </a>
                    </div>
                    </div>
                    </div>
                    </div>

            <div className='values-title'>
            <h1 className='growth'>FOUR WAYS WE BOOST PLATFORM GROWTH</h1>
            <img src='./images/growth_border.png' className='border-growth' alt=''/>
            </div>
                <div className='values-flex'>
                    <div id='innovation' className='values-image-left'>
                    <img className='left' src='./images/innovation.png' alt='Innovation'/>
                    </div>
                    <div className='values-image-right'>
                    <img  className='right' src='./images/listening.png' alt='Listening'/>
                    </div>
                    <div className='values-image-left'>
                    <img className='left' src='./images/customization.png' alt='Customization'/>
                    </div>
                    <div className='values-image-right'>
                    <img className='right' src='./images/impact.png' alt='Impact'/>
                    </div>
                </div>
            <div className='bottom-banner'>
                <h1>BRIDGING THE GAP BETWEEN DATA AND ACTION</h1>
                <img src='./images/bridging_border.png' className='border-bridge' alt=''/>
                <p>Better access to information enables better outcomes, and that's how we make progress for 
                individuals, industries, and society as a whole.</p>
                <h3>Let's break some barriers and see what's possible.</h3>
            </div>
            
{/*
            <div className="flex-box-3">
                <div className='home-background'>
                <img src='./images/investor-1.webp'/>
            <h1>Determined to change the world <span className = "word-break"> one industry at a time.</span></h1>
            <Button variant={"contained"} className='about-us-button'>About Us</Button>
            <div className='break'></div>
            <div className='flex-box-saule'>
                <div className='saule-text'>
                    <h3>It takes 7 apps to support one client.</h3>
                    <p>Why not just one?</p>
                    <div className='features-list'>
                    <img className='icon' src='./images/Group 3checkmarkicon.svg'/>
                    <h3 className='feature'>Session booking & scheduling</h3>
                    </div>
                    <div className='features-list'>
                    <img className='icon' src='./images/Group 3checkmarkicon.svg'/>
                    <h3 className='feature'>Seamless Referrals</h3>
                    </div>
                    <div className='features-list'>
                    <img className='icon' src='./images/Group 3checkmarkicon.svg'/>
                    <h3 className='feature'>Real-time Video Calling</h3>
                    </div>
                    <div className='features-list'>
                    <img className='icon' src='./images/Group 3checkmarkicon.svg'/>
                    <h3 className='feature'>Journaling & Note-taking features</h3>
                    </div>
                    <p>Among many other features.</p>
                    </div>
                    <div className='saule-screenshot'>
                    <img src='./images/iphone_home_screenshot 2.png'/>
                    </div>
                </div> 
                </div>  
            </div> */}

            {/* <div className="flex-box-4">
                <div className='image'>
                    <img src="./images/pexels-cottonbr.jpg" alt="therapist leading session"/>
                </div>
                <div className="titles">
                    <div className="text">
                        <h2>A mission to<br/>enhance healthcare</h2>
                        <p>Karen Thornton is a passionate
                            practitioner and entrepreneur who recognized the
                            changing landscape of healthcare and the
                            challenges facing professionals that
                            live and work within that space today.</p>
                        <p>More and more services are moving into digital platforms.
                            With so many separate channels of interactions and data,
                            many professionals find it harder to meet clients' needs,
                            or don’t have a ‘helicopter view’ of their entire practice
                            to provide the most effective treatment. What would be possible
                            if they did?</p>
                        <p>How could healthcare practitioners and their
                            teams offer better care with a full-picture
                            view of their data?</p>
                    </div>

                </div>
                <div className="helper-break-4"></div>

            </div> */}
{/* 
            <div className="flex-box-5">
                <div className="aversa-pro-rect"></div>
                <div className="text-1">
                    <h2>SAULE</h2>
                    <h3>With a desire to ditch data silos and make virtual healthcare better,
                        Karen and her team built an application called Saule to draw clients
                        and clinicians into the conversation around management and effectiveness of treatment.
                    </h3>
                </div>
                <div className="text-2">
                    <p>Saule connects multiple channels of
                        information like networking groups,
                        individual sessions, sessions supervision,

                        referrals, treatment plans, scheduling,
                        communities, and resources… all in one
                        place. It’s an inclusive ecosystem of
                        knowledge-sharing that enables more
                        efficient and intuitive care, both for the
                        patient and the practitioner.
                        <br/> <br/>
                        Yes, online therapy apps exist, but are they
                        designed to evolve the practice and the
                        patient simultaneously? Saule is
                        expanding data access in healthcare to
                        impact outcomes for patients and providers
                        in exponential ways.</p>
                </div>

                <div className='button-container'>
                    <div className="button">

                        <Button variant={"contained"} className='join-waitlist-button' onClick={handleClickOpen}>
                            Join the waitlist
                        </Button>
                        <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>

                            <div className='popup-form'>

                                <h1>Be the first to know</h1>
                                <p>Share a few details and we'll notify you when Saule has officially launched.</p>
                                <form>
                                    <div className='form-flex'>
                                        <div className='form-box'>

                                            <TextField type="text" id="name" value={name}
                                                       onChange={(e) => handleInputChange(e)} className="name"
                                                       label="Name" variant="outlined"/>

                                        </div>
                                        <div className='form-box'>

                                            <TextField type="email" value={email} onChange={(e) => handleInputChange(e)}
                                                       className="emails" id="email" label="Email" variant="outlined"/>

                                        </div>
                                        <div className='form-box'>
                                            <TextField id="number" type="tel" value={number}
                                                       onChange={(e) => handleInputChange(e)} className="numbers"
                                                       label="Number" variant="outlined"/>

                                        </div>
                                        <div className='form-box'>

                                            <TextField id="select" className="select" select label="Are you? (Select)"
                                                       value={select} onChange={handleChange}>

                                                {selections.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>

                                        </div>
                                    </div>
                                    <Reaptcha className='verify'
                                              sitekey="6LeEaUkhAAAAAEHVlRqJIua4WU-ZRzd71mc6Ky8O"
                                              ref={captchaRef}
                                              onVerify={verify}
                                              onChange={useCallback(() => setDisableSubmit(false))}
                                    />
                                </form>


                                <DialogActions>
                                    <Button className="close-popup" onClick={handleClose}>x</Button>
                                    <div className='submit-container'>
                                        <Button variant={"contained"} className="submit-button" disabled={disableSubmit}
                                                type="submit" onClick={handleSubmit}>Submit</Button>
                                    </div>
                                </DialogActions>
                            </div>
                        </Dialog>

                    </div>
                    <Link to="/products">
                        <Button variant={"contained"} className='learn-more-button'>Learn More</Button>
                    </Link>
                </div>
            </div>

            <div className="flex-box-6">

                <div className="helper-div">
                    <div className="text-1">
                        <h1>
                            An invest- <br/>ment in something bigger
                        </h1>
                    </div>
                    <div className="break"></div>
                    <div className="text-2">
                        <p>Healthcare isn't the only industry that could benefit from better
                            ways of working and more informed decision-making. Knowing that better data access could
                            significantly
                            impact other sectors like healthcare, education and the legal system, Aversa was born.

                            <br/> <br/>
                            Aversa is a company that is looking at the way professionals access information, or are
                            restricted from it,
                            and saying, "we can do better." We can get a better view, we can make better informed
                            decisions, and we can have
                            a better impact. We're building the platform to support this attitude, one sector at a time.
                        </p>
                    </div>
                </div>
            </div>

            <div className="flex-box-7">
                <div className="text-1">
                    <h2>We'll accelerate the growth of our platforms, and the growth of the sectors they serve, in four
                        different ways.</h2>
                </div>
                <div className="break"></div>
                <div className="fun-text">
                    <div className="text-2" id="one">
                        <h1>Innovation</h1>
                        <p>We're working to address what our world needs next.</p>
                    </div>
                    <div className="text-2" id="two">
                        <h1>Listening</h1>
                        <p>We enable more communication to make more progress.</p>
                    </div>
                    <div className="text-2" id="three">
                        <h1>Customization</h1>
                        <p>We create tools as unique as every person.</p>
                    </div>
                    <div className="text-2" id="four">
                        <h1>Impact</h1>
                        <p>Our innovations have meaningful consequences.</p>
                    </div>
                </div>
            </div>

            <div className="flex-box-8">
                <div className="text-1">
                    <h1>Building the bridge between data and action.</h1>
                    <p>Better access to information enables better outcomes, and that's how we make progress for
                        individuals, industries, and society as a whole.
                        <br/> <br/>
                        Let's break some barriers and see what's possible.</p>

                </div>
                <div className="aversa-pro-rect"></div>
            </div> */}
        </div>
    );
    
};

export default Home;