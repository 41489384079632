import { Outlet, Link } from "react-router-dom";
import "./Layout.css"
import react, {useState} from 'react'




function Layout(){


const [click, setClick] = useState(false);
   
const handleClick = () => setClick(!click);

const closeMobileMenu = () => setClick(false);


const scrollToTop = () => {
   setClick(false);
   window.scrollTo({
     top: 0,
     behavior: 'smooth' // for smoothly scrolling
   });
 };
const scrollToBottom = () => {
   setClick(false);
   window.scrollTo({
     top: 1000000,
     behavior: 'smooth' // for smoothly scrolling
   });
 };
 
  return (
    <>
    <nav className = "navbar">
      


      
            
            <Link className = "navbar-logo"  onClick={scrollToTop} to="/">
              <img id = "submit" className = "logo-nav" src="./images/Aversa Logo 1x White 3.png" alt="logo"/>
            </Link>

            <div className='menu-icon' onClick={handleClick }>
              <i className={click ? 'fa-solid fa-x' : 'fa-solid fa-bars'} />
            </div>

            <ul className={click ? 'nav-menu active' : 'nav-menu'}>

              <li className='nav-item'>
                 <Link id = "submit" className="nav-links" to="/" onClick={scrollToTop}>Home</Link>
              </li>
              {/* <li className='nav-item'>
                 <Link  className="nav-links" to="/about" onClick={closeMobileMenu}>About</Link>
              </li> */}
              <li className='nav-item'>
                 <Link  id = "submit" className="nav-links" to="/about" onClick={scrollToTop}>About</Link>
              </li>
              {/* <li className='nav-item'>
                 <Link  id = "submit" className="nav-links" to="/products" onClick={scrollToTop}>Products</Link>
              </li> */}
              {/* <li className='nav-item'>
                 <Link  className="nav-links" to="/news" onClick={closeMobileMenu}>News</Link>
              </li> */}
              <li className='nav-item'>
                 <Link id = "submit" className="nav-links" to="/careers" onClick={scrollToTop}>Careers</Link>
              </li>
              <li className='nav-item'>
                 <button id = "submit" className="nav-links" to onClick={scrollToBottom}>Contact Us</button>
              </li>

            </ul>
           
                
     
    </nav>

    <Outlet />
    </>
  )
};

export default Layout;