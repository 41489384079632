// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCbty1kwl9VH60Ug_r6S3X9dafnUqYw0_c",
  authDomain: "aversa-3a4bb.firebaseapp.com",
  databaseURL: "https://aversa-3a4bb-default-rtdb.firebaseio.com",
  projectId: "aversa-3a4bb",
  storageBucket: "aversa-3a4bb.appspot.com",
  messagingSenderId: "721954152814",
  appId: "1:721954152814:web:c6e98a095fb31b9da3a17f",
  measurementId: "G-BY59VGEZBR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);



export const database = getDatabase(app);