import React from "react";
import {Container, Wrapper, Row, InvisibleRow, TopRow, RightColumn, MiddleColumn, LeftColumn, LeftBodyText, RightBodyText, Title, Holder, Button, Image, BoldText} from './styles/newFooter'

export default function Footer({ children, ...restProps}){
    return <Container {...restProps}>{children}</Container>
}

Footer.Wrapper = function FooterWrapper({children, ...restProps}){
    return <Wrapper {...restProps}>{children}</Wrapper>
}

Footer.TopRow = function FooterTopRow({children, ...restProps}){
    return <TopRow {...restProps}>{children}</TopRow>
}

Footer.Row = function FooterRow({children, ...restProps}){
    return <Row {...restProps}>{children}</Row>
}

Footer.InvisibleRow = function FooterInvisibleRow({children, ...restProps}){
    return <InvisibleRow {...restProps}>{children}</InvisibleRow>
}

Footer.RightColumn = function FooterRightColumn({children, ...restProps}){
    return <RightColumn {...restProps}>{children}</RightColumn>
}

Footer.MiddleColumn = function FooterMiddleColumn({children, ...restProps}){
    return <MiddleColumn {...restProps}>{children}</MiddleColumn>
}

Footer.LeftColumn = function FooterLeftColumn({children, ...restProps}){
    return <LeftColumn {...restProps}>{children}</LeftColumn>
}


Footer.LeftBodyText = function FooterLeftBodyText({children, ...restProps}){
    return <LeftBodyText {...restProps}>{children}</LeftBodyText>
}

Footer.RightBodyText = function FooterRightBodyText({children, ...restProps}){
    return <RightBodyText {...restProps}>{children}</RightBodyText>
}

Footer.Title = function FooterTitle({children, ...restProps}){
    return <Title {...restProps}>{children}</Title>
}

Footer.Holder = function FooterHolder({children, ...restProps}){
    return <Holder {...restProps}>{children}</Holder>
}

Footer.Button = function FooterButton({children, ...restProps}){
    return <Button {...restProps}>{children}</Button>
}

Footer.Image = function FooterImage({src, alt}){
    return <img src={src} alt={alt} />
}

Footer.BoldText = function FooterBoldText({children, href, ...restProps}){
    return <BoldText href={href} {...restProps}>{children}</BoldText>
}
