//IMPORTS
import ReactGA from 'react-ga';
import './App.css';
import ReactDOM from "react-dom/client";
import { EasybaseProvider, useEasybase } from 'easybase-react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import About from "./components/About/About";
import Careers from "./components/Careers/Careers";
import ContactUs from "./components/Contact Us/Contactus";
import Home from "./components/Home/Home";
import Investors from "./components/Investors/Investors";
import News from "./components/News/News";
import Footer from "./components/Footer/Footer";
import { FooterContainer } from "./components/newFooter/containers/footer";
import Products from "./components/Products/Products";
import ScrollToTop from "./ScrollToTop";
import { Outlet, Link } from "react-router-dom";
import React from 'react';
import { useEffect, useState } from "react";
import Chatbot from 'react-chatbot-kit'
import { Header } from 'semantic-ui-react';

const App=() => {
  
  // The back-to-top button is hidden at the beginning
  const [showButton, setShowButton] = useState(false); 

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300 ) {
        setShowButton(true);
      } 
      else {
        setShowButton(false);
      }
    });
  }, []);

  // This function will scroll the window to the top 
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    });
  };

  return (
    <div className="App">
      <header className="App-header">
      
    <BrowserRouter>
    <ScrollToTop/>
      <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path = "about" element ={<About />} />
            {/* <Route path = "products" element ={<Products />} /> */}
            <Route path = "news" element ={<News />} />
            <Route path = "investors" element ={<Investors/>} />
            <Route path = "careers" element ={<Careers />} />
            <Route path = "contactus" element ={<ContactUs />} />
          </Route>
      </Routes>
      {showButton && (
        <button onClick={scrollToTop} className="back-to-top">
          <img src="./images/scroll_to_top.png" alt="return to top button" />
        </button>
      )}
      <Header>
      </Header>
      <Footer/>
    </BrowserRouter>


    
        
    



        
      </header>
    </div>
  );
}

export default App;
