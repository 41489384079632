import "./About.css"

const About = () => {
    return (
      <div className = "about-flex">

          <div className='title'>
            <div className='row'>
            <h1>ABOUT US</h1>
            </div>
            <div className='row'>
            <img className='border-about' src='./images/careers-border.png' alt=""/>
            </div>
        </div>
        <div className="about-bg">
          <img src="./images/group-photo_bigbg-4-_1_.webp" alt=""/>
        </div>

        <div className='about-section-1'>
            <div className='row'>
            <div className='caption'>
                <h2 className='first-caption'>At Aversa, we're <span id="bold">building a platform</span> to  <span id="bold">change the way professionals 
                access data,</span> with a mission to <span id="bold">enhance healthcare </span>and other industries. </h2>
                <img className='border-about' src='./images/mobile-border-careers.png' id='mobile-border-about' alt=""/>
            </div>
            <div className='images'>
                <img className='left-img' src='./images/about1.png' alt="Speaking at Event"/>
                <img className='right-img' src='./images/about2.png' alt="Group Working Together"/>
            </div>
            <div className='mobile-image-1'>
            <img className='left-img' src='./images/about1.png' alt="Speaking at Event"/>
            </div>
            </div>
        </div>

            {/* <div className='gradient-bar'/> */}

        <div className='about-section-1' id='careers-two'>
            <div className='row'>
            <div className='images'>
                <img className='left-img-2' src='./images/about3.png' alt="Brainstorming ideas"/>
                <img className='right-img-2' src='./images/about4.png' alt="Group meeting"/>
            </div>
            <div className='caption'>
            <h2 className='first-caption' id="caption-two">Our<span id="bold"> commitment to innovation</span> and <span id="bold">meaningful impact
                </span> drives us to <span id="bold"> break barriers and accelerate progress </span> for individuals, industries, and society. </h2>
                </div>
                <img className='border-about' src='./images/mobile-border-careers.png' id='mobile-border-about'/>
            <div className='mobile-image-1'>
            <img className='left-img' src='./images/about3.png' alt="Brainstorming ideas"/>
            </div>
            </div>
        </div>

        {/* <div className="about-flex-2">
        <h1> Mission Statement </h1>
        <p>At Aversa, we are building a platform to change the way professionals access data, 
          with a mission to enhance healthcare and other industries. 
          Our commitment to innovation and meaningful impact drives us to break barriers and 
          accelerate progress for individuals, industries, and society.
        </p>
</div> */}

        <div className="about-flex-3">
        <h1 className="aversa-team-header">Meet The Aversa Team</h1>
        <img className='border-about' id="team-border" src='./images/growth_border.png'/>
            <div className="people">
          <div className="about-person">
        <img src="./images/Karen8-modified.png" alt="Profile picture" />
      
          <h1>Karen Thornton</h1>
          <h3>Founder/CEO</h3>
        </div>

        <div className="about-person">
        <img src="./images/alon.png" alt="Profile picture" />
          <h1>Alon Zohar</h1>
          <h3>Advisor</h3>
        </div>

        <div className="about-person">
        <img src="./images/placeholder.png" alt="Profile picture" />
          <h1>Mustafa Chhil</h1>
          <h3>Software Engineer</h3>
        </div>

        <div className="about-person">
        <img src="./images/sarim.png" alt="Profile picture" />
          <h1>Sarim Zia</h1>
          <h3>Product Manager</h3>
        </div>

        <div className="about-person">
        <img src="./images/dorota.png" alt="Profile picture" />
          <h1>Dorota Roberts</h1>
          <h3>Product Specialist</h3>
        </div>

        <div className="about-person">
        <img src="./images/mahfuza.png" alt="Profile picture" />
          <h1>Mahfuza Rahman</h1>
          <h3>Marketing Director</h3>
        </div>

        <div className="about-person">
        <img src="./images/placeholder.png" alt="Profile picture" />
          <h1>Joseph Petrasek</h1>
          <h3>UI/UX Designer</h3>
        </div>

        <div className="about-person">
        <img src="./images/placeholder.png" alt="Profile picture" />
          <h1>Hamzah Rawasia</h1>
          <h3>Software Engineer</h3>
        </div>

        <div className="about-person">
        <img src="./images/placeholder.png" alt="Profile picture" />
          <h1>Jaismeen Gabria</h1>
          <h3>Bookkeeper</h3>
        </div>
        </div>

        
       
          {/* <div className="about-person">
        <img src="./images/Karen8-modified.png" alt="Profile picture" />
          <h1>Sarim Zia</h1>
          <h3>Product Manager/<br>Software Engineer</br></h3>
        </div>

        <div className="about-person">
        <img src="./images/Karen8-modified.png" alt="Profile picture" />
          <h1>Dorota Roberts</h1>
          <h3>Product Specialist</h3>
        </div>

        <div className="about-person">
        <img src="./images/Karen8-modified.png" alt="Profile picture" />
          <h1>Mahfuza Rahma</h1>
          <h3>Marketing Director</h3>
        </div>
         */}
        </div>

     </div>
    );
   
  };
  
  export default About;