import styled from 'styled-components';

export const Container= styled.div`

`

export const Wrapper= styled.div`
    position: relative;
    background: linear-gradient(90deg, #7A07D9 0%, #007BFF 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2.5em 1em;
`

export const TopRow = styled.div`
    display: grid;
    text-align: center;
    align-items: center;
    margin: 1em 0;
    grid-template-areas: 
                    "a b"
                    "c c";
    grid-template-columns: 70vw 20vw;
    @media(max-width: 550px){
        grid-template-columns: 55vw 35vw;
    }
    @media(min-width: 1080px){
        grid-template-columns: 34vw 20vw 34vw;
        grid-template-areas: 
                    "a b c";
    }
    
`

export const RightColumn = styled.div`
    display: flex;
    grid-area: c;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 0.6125em 0em;
    @media(min-width:1080px){
        padding: 1.25em 0em;
    }
`

export const MiddleColumn = styled.div`
    display: flex;
    grid-area: b;
    flex-direction: column;
    text-align: right;
    margin-right: 1.8vw;
    align-items: flex-end;
    @media(min-width:1080px){
        margin: 2.5em 1.5em;
        align-items: center;
    }
`

export const LeftColumn = styled.div`
    display: flex;
    grid-area: a;
    flex-direction: column;
    text-align: center;
    margin: 1.8vw 0em;
    margin-left: 1.2em;
    align-items: flex-start;
    @media(min-width:1080px){
        margin: 1.8vw 0em;
        align-items: center;
    }
`

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    margin: 1em 0;
`

export const InvisibleRow = styled.div`
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    margin: 0;
    @media(max-width:550px){
        flex-direction: column;
        align-items: flex-start;
    }
`

export const LeftBodyText = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    margin: 0.25em 0.5em;
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 1.3125em;
    display: flex;
    text-align: left;
    align-items: center;
    color: #FFFFFF;
    justify-content: center;
    
    @media(max-width: 720px){
        font-size: 0.9375em;
        
    }
    @media(max-width: 500px){
        font-size: 0.875em;
    }

    @media(max-width: 360px){
        font-size: 0.6875em;
    }
`


export const RightBodyText = styled.div`
    position: relative;
    width: 100%;
    height: auto;
    margin: 0em 0.25em;
    font-family: 'PT Sans';
    white-space: no-wrap;
    font-style: normal;
    font-weight: 400;
    font-size: 1.3125em;
    display: flex;
    text-align: center;
    align-items: center;
    color: #007BFF;
    justify-content: center;
    @media(max-width: 720px){
        font-size: 0.875em;
    } @media(max-width: 360px){
        font-size: 0.625em;
    } 
`

export const Title = styled.div`
    position: relative;
    display: flex;
    margin: 0.5em;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 1.875em;
    line-height: 37px;
    text-align: center;
    color: #FFFFFF;
    @media(max-width: 720px){
        font-size: 1.25em;
    } @media(max-width: 360px){
        font-size: 1em;
    }
`

export const Holder = styled.div`
    position: relative;
    display: flex;
    width: 20vw;
    height: 100%;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    padding: 0.75em 1em;
    border-radius: 18px;
    align-items: center;
    margin-top: 0.75em;
    margin-bottom: 0.75em;
    @media(max-width: 1080px){
        width: 60vw;
    }
`

export const Button = styled.div`
    position: relative;
    display: flex;
    width: 10vw;
    height: 100%;
    padding: 0.75em 0.5em;
    margin-top: 0.75em;
    margin-bottom: 1em;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 999999px;
    @media(max-width: 1080px){
        width: 20vw;
    }
`

export const Image = styled.div`
    position: absolute;
`

export const BoldText = styled.div`
    position: relative;
    display: flex;
    margin: 0.25em 1em;
    font-family: 'PT Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 1.875em;
    color: #FFFFFF;
    @media(max-width: 720px){
        font-size: 1.25em;
    } @media(max-width: 360px){
        font-size: 1.00em;
    }
`