import React, { Component } from "react";
import { renderMatches } from "react-router-dom";

class EmbedSocialWidget extends Component {
    componentDidMount() {
        (function(d, s, id) { 
            var js; 
            if (d.getElementById(id)) {
                d.getElementById(id).parentNode.removeChild(d.getElementById(id));
            } 
            js = d.createElement(s); 
            js.id = id; 
            js.src = "https://embedsocial.com/cdn/ht.js"; 
            d.getElementsByTagName("head")[0].appendChild(js);
         } (document, "script", "EmbedSocialHashtagScript"));
      }

render() {
    return (
        <div 
        class="embedsocial-hashtag" 
        data-ref="292fcdd8416c82d91d7a40e179434bd03c2ab9c5">
        </div>
    );
}
}
export default EmbedSocialWidget;