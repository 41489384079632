import React from 'react';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';
import { Form, Input, TextArea, Button } from 'semantic-ui-react';
import "./Contactus.css";
import reCAPTCHA from "react-google-recaptcha";

const SERVICE_ID = "service_3vkf8tb";
const TEMPLATE_ID = "template_9r7de2f";
const USER_ID = "et393ELm3au7tfFML";


const Contactus = () => {
  const handleOnSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID)
      .then((result) => {
        console.log(result.text);
        Swal.fire({
          icon: 'success',
          title: 'Message Sent Successfully'
        })
      }, (error) => {
        console.log(error.text);
        Swal.fire({ 
          icon: 'error',
          title: 'Ooops, something went wrong',
          text: error.text,
        })
      });
    e.target.reset()
  };

  
  return (
    <div className="contactform">
      <h1>Contact Us</h1>
      <Form onSubmit={handleOnSubmit}>
        <Form.Field
          id='form-input-control-email'
          control={Input}
          name='user_email'
          placeholder='Email…'
          required
          icon='mail'
          iconPosition='left'
        />
        <div className = "name">
        <Form.Field
          id='form-input-control-last-name'
          control={Input}
          name='user_name'
          placeholder='Name…'
          required
          icon='user circle'
          iconPosition='left'
        />
        </div>
        <div className="message">
        <Form.Field
          id='form-textarea-control-opinion'
          control={TextArea}
          name='user_message'
          placeholder='Message…'
          required
        />
        </div>
        <reCAPTCHA />
        <Button type='submit' className="button-submit">Submit</Button>
      </Form>
    </div>
  );
}
export default Contactus;







