import './Footer.css';
import {Outlet, Link} from "react-router-dom";

const generalEmailName = "info"
const supportEmailName = "support"
const emailDomain = "aversa"
const emailDomainTld = "ca"

const Footer = () => {

    const getGeneralEmail = () => {
        return `mailto:${generalEmailName}@${emailDomain}.${emailDomainTld}`
    }

    const getSupportEmail = () => {
        return `mailto:${supportEmailName}@${emailDomain}.${emailDomainTld}`
    }

    return (
        <div className="footer-container">
            <div className="contact-info" id={"contact"}>
                <h4>Head Office</h4>
                <div className="white-line"></div>
                <p>A204-1339 Ironoak Way<br/>
                    Oakville, ON<br/>
                    L6H 7G4 - Canada</p>
                <p>
                    <a href="tel:647-237-9212"><span className="number"> </span></a> <br/>
                    <a href={getGeneralEmail()}><span className="email"> </span></a> <br/>
                    <a href={getSupportEmail()}><span className="support-email"> </span></a>
                </p>

                <p>Hours of operation:<br/>
                    Monday-Friday, 8:00 AM-8:00 PM <br/>
                    Saturday, 9:00 AM-6:00 PM
                </p>

            </div>

            <div className="links">
                <h4>Quick Links</h4>
                <div className="white-line"></div>
                <Link className="links-text" to="/">
                    <p>Home</p>
                </Link>
                <Link className="links-text" to="/about">
                    <p>About</p>
                </Link>
                <Link className="links-text" to="/careers">
                    <p>Careers</p>
                </Link>
                <a href="https://joinsaule.com">
                    <p>Saule</p>
                </a>
            </div>

            <div className='aversa-footer-logo'>
                <img src="./images/Group 175.png" alt="aversa logo"/>
            </div>

        </div>

    );
};

export default Footer;