import './Careers.css';
import { Outlet, Link } from "react-router-dom";

const Careers = () => {

  return (
    
    <div className="careers-flex">
        <div className='title'>
            <div className='row'>
            <h1>CAREERS</h1>
            </div>
            <div className='row'>
            <img className='border-careers' src='./images/careers-border.png' alt=''/>
            </div>
        </div>

        <div className='careers-section-1'>
            <div className='row'>
            <div className='caption'>
                <h2 className='first-caption'>Careers that inspire change: Join our team to make a difference</h2>
                <img className='border' src='./images/mobile-border-careers.png' id='mobile-border' alt=''/>
            </div>
            <div className='images'>
                <img className='left-img' src='./images/image 2careers1.png' alt='Group discussing ideas'/>
                <img className='right-img' src='./images/image 3careers2.png' alt='Positive Working Environment'/>
            </div>
            <div className='mobile-image-1'>
            <img className='left-img' src='./images/image 2careers1.png' alt='Group discussing ideas'/>
            </div>
            </div>
        </div>

            {/* <div className='gradient-bar'/> */}

        <div className='careers-section-1' id='careers-two'>
            <div className='row'>
            <div className='images'>
                <img className='left-img-2' src='./images/image 4careers3.png' alt='Helping others'/>
                <img className='right-img-2' src='./images/image 5careers4.png'/>
            </div>
            <div className='caption'>
                <h2 className='first-caption'>Opportunities for Students</h2>
                <img className='border' src='./images/mobile-border-careers.png' id='mobile-border' alt='Collaborating on tasks'/>
                <p className='text'>Aversa offers a diverse and inclusive work environment that encourages growth & learning. </p>
            </div>
            <div className='mobile-image-1'>
            <img className='left-img' src='./images/image 4careers3.png' alt=''/>
            </div>
            </div>
        </div>

        <div className='flex-box-banner'>
        <div className='banner-image'>
        <img src='./images/careers-banner.webp' alt=''/>
        </div>
        <div className='mobile-banner-image'>
        <img src='./images/careersBannerMobile-1.webp' alt=''/>
        </div>
        <div className='first-header'>
        <h3>Mental Health</h3>
        </div>
        <div className='second-header'>
          <h3>Law</h3>
        </div>
        <div className='third-header'>
          <h3>Education</h3>
        </div>
        <div className='three-banner-caption'>
          <p>Aversa provides opportunities to work on meaningful projects and make a difference in people's 
            lives. Join Aversa today and discover how you can contribute to a brighter future.</p>
        </div>
      </div>
      <div className="flex-box-4">

        <div className="listing">
          <h1>Jr. Software Engineer</h1>
          <h3>Toronto, Canada</h3>
          <a href = "mailto:info@aversa.ca" target="_blank">Apply Now</a>
        </div>
        <div className="listing">
          <h1>Technical Support </h1>
          <h3>Toronto, Canada</h3>
          <a href = "mailto:info@aversa.ca" target="_blank">Apply Now</a>
        </div>
        <div className="listing">
          <h1>Sales Associate</h1>
          <h3>Toronto, Canada</h3>
          <a href = "mailto:info@aversa.ca" target="_blank">Apply Now</a>
        </div>
        <div className="listing">
          <h1>Administrative Assistant</h1>
          <h3>Toronto, Canada</h3>
          <a href = "mailto:info@aversa.ca" target="_blank">Apply Now</a>
        </div>
        <div className="listing">
          <h1>Accountant</h1>
          <h3>Toronto, Canada</h3>
          <a href = "mailto:info@aversa.ca" target="_blank">Apply Now</a>
        </div>
        <div className="listing">
          <h1>Customer Representative</h1>
          <h3>Toronto, Canada</h3>
          <a href = "mailto:info@aversa.ca" target="_blank">Apply Now</a>
        </div>

      </div>

    </div>
  );
};

export default Careers;